<template>
  <div>
    <Toolbar class="mb-4">
      <template #right>Neue hinzufügen</template>
      <template #left>
        <Button icon="pi pi-times" class="p-button-danger" @click="$router.push('/admin/carts')" />
      </template>
    </Toolbar>
    <div class="form-card row">
      <!-- <div class="col-md-4">
        <div class="mb-3">
          <label for="code" class="form-label"> S.N </label>
          <input
            type="text"
            class="form-control"
            id="code"
            v-model="body.code"
            required
          />
        </div>
      </div> -->
      <div class="col-md-4">
        <div class="mb-3">
          <label for="date" class="form-label required">
            Datum
            <span>*</span>
          </label>
          <input type="text" class="form-control" id="date" :value="$durationFormat(body.date)"
            :class="{ 'is-invalid': isInvalid && !body.date }" disabled />
        </div>
      </div>

      <div class="col-md-4">
        <div class="mb-3">
          <label for="total" class="form-label required">
            Gesamt
            <span>*</span>
          </label>
          <input disabled class="form-control" id="total" :value="getTotal() + '€'"
            :class="{ 'is-invalid': isInvalid && !body.total }" required />
        </div>
      </div>

      <div class="col-md-4">
        <div class="mb-3">
          <label for="user" class="form-label required">
            Benutzer
            <span>*</span>
          </label>
          <input disabled class="form-control" id="user"
            :value="id && body && body.usersId ? body.usersId.fullName : userName" required />
        </div>
      </div>

      <div class="col-md-12">
        <div class="mb-3">
          <label for="items" class="form-label mr-2">Artikel</label>

          <Button label="Add" icon="pi pi-external-link" @click="openBasic" />
          <Dialog header=" " :visible.sync="displayBasic" containerStyle="width: '50vw'" id="xxxx">
            <div class="m-0" style="width: 50%">
              <!-- get category by CascadeSelect  and use watch to get products for this category-->
              <!-- <div class="branches">
                <label for="category" class="form-label required">
                  Kategorie
                  <span>*</span>
                </label>
                <CascadeSelect
                  v-model.number="catId"
                  :options="catList"
                  optionLabel="name"
                  optionValue="id"
                  optionGroupLabel="name"
                  style="width: 100%"
                  :showClear="true"
                  :optionGroupChildren="['children']"
                  placeholder="Select Category"
                  required
                />
              </div> -->
              <div class="branches">
                <label for="product" class="form-label required">
                  Produkt
                  <span>*</span>
                </label>
                <input type="text" class="form-control" v-model="itemId">
              </div>
              <div class="branches">
                <label for="product" class="form-label required">
                  Produkt
                  <span>*</span>
                </label>
                <Dropdown :filter="true" v-model.number="purchasingItem.productsId" :options="productsList"
                  optionLabel="xname" optionValue="id" placeholder="Select Product" class="w-100" required />
              </div>
              <div class="branches">
                <label for="quantity" class="form-label required">
                  Menge
                  <span>*</span>
                </label>
                <b-form-spinbutton id="sb-default" v-model.number="purchasingItem.quantity" :min="1" class="mb-2"
                  required></b-form-spinbutton>
              </div>
            </div>
            <template #footer>
              <Button label="Yes" icon="pi pi-check" @click="closeBasic" autofocus />
            </template>
          </Dialog>
          <br />
          <br />
          <br />

          <a class="list-group-item list-group-item-action" v-for="(item, index) in body.cartsItemsList" :key="index">
            <div class="row">
              <div class="col-md-4">
                <div class="mb-1">
                  <Dropdown :filter="true" v-model="item.productsId" :options="productsList" optionLabel="xname"
                    optionValue="id" placeholder="Select Product" class="w-100" disabled />
                </div>
              </div>
              <div class="col-md-3">
                <p class="mb-1">
                  <b-form-spinbutton id="sb-default" v-model.number="item.quantity" :min="1"
                    class="mb-2"></b-form-spinbutton>
                </p>
              </div>
              <div class="col-md-3">
                <p class="mb-1 text-center">
                  {{
                    item.productsId
                      ? item.quantity * getcarts(item.productsId)
                      : 0
                  }}€
                  {{ setItemX(item) }}
                </p>
              </div>
              <div class="col-md-2">
                <p class="mb-1">
                  <small style="font-size: 176%; color: #af002d" @click="remove(index)">x</small>
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>

      <div class="col-md-12">
        <div class="mb-3">
          <label for="note" class="form-label required">Notiz</label>

          <Editor v-model="body.note" editorStyle="height: 320px" />
        </div>
      </div>

      <div class="col-md-12">
        <div class="mb-3 text-center">
          <Button label="Bearbeiten" icon="pi pi-pencil" class="ml-2 p-button-info" @click="update()" v-if="id"
            :disabled="disabledAdd" />
          <Button v-else label="Hinzufügen" icon="pi pi-plus" class="ml-2 p-button-success" @click="save()"
            :disabled="disabledAdd" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const d = new Date();

    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);

    const user = JSON.parse(localStorage.adminKB);

    return {
      body: {
        code: null,

        date: `${ye}-${mo}-${da}`,
        total: 0,
        note: null,
        cartsItemsList: [],
      },
      id: null,

      userName: user.fullName,
      itemList: [],
      isInvalid: false,
      disabledAdd: false,

      purchasingItem: {
        productsId: null,
        quantity: 1,
      },

      catId: null,
      displayBasic: false,
      catList: [],
      productsList: [],
      itemId: null,

    };
  },
  methods: {
    openBasic() {
      this.displayBasic = true;
      this.catId = null;
      this.purchasingItem = {
        productsId: null,
        quantity: 1,
      };
    },

    closeBasic() {
      this.displayBasic = false;

      if (this.purchasingItem.productsId) {
        this.body.cartsItemsList.push({ ...this.purchasingItem });
      }
    },
    setItemX(item) {
      item.price = this.getcarts(item.productsId);
    },
    getTotal() {
      let num = 0;
      for (const item of this.body.cartsItemsList) {
        if (item.productsId) {
          const t = item.quantity * this.getcarts(item.productsId);
          num = num + t;
        }
      }
      this.body.total = num;

      return num;
    },
    remove(index) {
      this.body.cartsItemsList.splice(index, 1);
    },
    getcarts(v) {
      if (v) {
        const item = this.productsList.find((i) => Number(i.id) == Number(v));

        return item?.price;
      }
      return 0;
    },
    save() {
      if (this.body.cartsItemsList.length > 0) {
        this.disabledAdd = true;

        this.$http.post(`carts`, this.body).then(
          () => {
            this.$toast.add({
              severity: "success",
              summary: "Done Successfully",
              detail: "Added Successfully",
              life: 3000,
            });
            this.disabledAdd = false;

            this.$router.push("/admin/carts");
          },
          (err) => {
            this.disabledAdd = false;

            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err.response.data.message,
              life: 3000,
            });
          }
        );
      } else {
        this.isInvalid = true;
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Please add the required fields",
          life: 3000,
        });
      }
    },
    update() {
      if (this.body.cartsItemsList.length > 0) {
        // delete this.body.list;
        // delete this.body.date;
        delete this.body.id;
        this.$http.put(`carts/${this.id}`, this.body).then(
          () => {
            this.$toast.add({
              severity: "success",
              summary: "Done Successfully",
              detail: "Updated Successfully",
              life: 3000,
            });
            this.disabledAdd = false;

            this.$router.push("/admin/carts");
          },
          (err) => {
            this.disabledAdd = false;

            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err.response.data.message,
              life: 3000,
            });
          }
        );
      } else {
        this.isInvalid = true;
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Please add the required fields",
          life: 3000,
        });
      }
    },
  },
  created() {
    this.$http.get(`categories/getTree?linkOrSection=section`).then((res) => {
      let catList = res.data.data;
      for (let cat of catList) {
        cat.name = this.$getByLang(cat.name);
        for (let child of cat.children) {
          child.name = this.$getByLang(child.name);
        }
      }

      this.catList = catList;
    });

    this.$http.get(`products`).then((res) => {
      let itemList = res.data.data;
      for (let item of itemList) {
        item.xname = item.code + " - " + item.pname;
      }
      this.productsList = itemList;

      if (this.$route.params.id) {
        this.id = this.$route.params.id;
        this.$http.get(`carts/${this.id}`).then(
          (res2) => {
            this.body.note = res2.data.data.note;
            this.body.code = res2.data.data.code;
            this.body.total = res2.data.data.total;
            this.body.date = res2.data.data.date;
            this.body.usersId = res2.data.data.usersId;

            for (let item of res2.data.data.cartsItemsList) {
              this.body.cartsItemsList.push({
                ...item,
                productsId: item.productsId.id,
              });
            }
          },
          (err) => {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err.response.data.message,
              life: 3000,
            });
          }
        );
      }
    });
  },
  watch: {
    catId: function (val) {
      if (val) {
        for (const i of this.productsList) {
          if (i.categoriesId && i.categoriesId.id == val) {
            this.productsList.push(i);
          }
        }
      }
    },
    itemId: function (val) {
      if (val) {
        const item = this.productsList.find((i) => String(i.code) == String(val));
        if (item) {
          this.purchasingItem.productsId = item.id;

        }
      }
    },
  },
};
</script>

<style></style>

<style>
.branches label {
  display: inline;
  text-align: start !important;
  margin-right: 11px;
}

#xxxx {
  width: 50vw;
  text-align: center;
}

#xxxx .p-dialog-content {
  height: 90vh;
}
</style>
